import React from 'react'
import { useStaticQuery, Link } from 'gatsby'
import styled from 'styled-components'
// import PageTitle from '../components/PageTitle'
// import Container from '../components/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const Text = styled.p`
  text-align: center;
  line-height: 1.6;
  a {
    color: 'red';
  }
`

// <PageTitle>Page Not Found</PageTitle>

const NotFoundPage = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  // console.log(props)

  const siteData = data.site.siteMetadata;
  const siteTitle = siteData.title;
  const siteDescription = siteData.description;

  return (
    <Layout>
      <SEO title="404" description="Page Not Found" />
      {/* <Container> */}
        
        <div>{siteTitle}</div>
        <div>{siteDescription}</div>

        <Text>
          Please return <Link to="/">home</Link> or use the menu to navigate to a
          different page.
        </Text>
      {/* </Container> */}
    </Layout>
  )
}

export default NotFoundPage

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//         description
//       }
//     }
//   }
// `